import React, { FC, useCallback, useContext, useMemo, useState } from "react";

export interface TranslationCacheContextState {
  getCachedTranslation: (
    lang: Language,
    en: string | null | undefined,
  ) => string | null | undefined;
  addToTranslationCache: (args: {
    targetLang: Language;
    sourceText: string;
    targetText: string;
  }) => void;
}

export const TranslationCacheContext = React.createContext<
  TranslationCacheContextState | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};

const TranslationCacheContextProvider: FC<Props> = ({ children }) => {
  const [translationCache, setTranslationCache] = useState<
    // the second map is from the english text to the translated text
    Map<Language, Map<string, string>>
  >(new Map());
  const getCachedTranslation = useCallback(
    (lang: Language, en: string | null | undefined) => {
      if (!en) {
        return en;
      }
      return translationCache.get(lang)?.get(en);
    },
    [translationCache],
  );
  const addToTranslationCache = useCallback(
    (args: {
      targetLang: Language;
      sourceText: string;
      targetText: string;
    }) => {
      setTranslationCache((prev) => {
        const { targetLang, sourceText, targetText } = args;
        if (sourceText === "") {
          return prev;
        }
        if (prev.get(targetLang)?.get(sourceText) === targetText) {
          return prev;
        }
        const newMap = new Map(prev);
        const innerMap = newMap.get(targetLang) || new Map();
        innerMap.set(sourceText, targetText);
        newMap.set(targetLang, innerMap);
        return newMap;
      });
    },
    [],
  );
  const state: TranslationCacheContextState = useMemo(() => {
    return {
      getCachedTranslation,
      addToTranslationCache,
    };
  }, [addToTranslationCache, getCachedTranslation]);
  return (
    <TranslationCacheContext.Provider value={state}>
      {children}
    </TranslationCacheContext.Provider>
  );
};

export default TranslationCacheContextProvider;

export const useTranslationCacheContext = (): TranslationCacheContextState => {
  const contextVal = useContext(TranslationCacheContext);
  if (!contextVal) {
    throw Error("forgot to initialize TranslationCacheContext");
  }
  return contextVal;
};
