import { useEffect } from "react";
import {
  clientHasStaleAssets,
  getDashboardWindowLastActivityAt,
  setDashboardWindowLastActivityAt,
} from "@utils/assetsInfo";
import { dateDiffMs } from "@utils/dates";

export const MS_IN_3_HOURS = 1000 * 60 * 60 * 3;

const useRefreshStaleInactiveTabs = (): void => {
  useEffect(() => {
    const handleActivity = () => {
      setDashboardWindowLastActivityAt();
    };
    document.addEventListener("scroll", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("mousedown", handleActivity);

    return () => {
      document.removeEventListener("scroll", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("mousedown", handleActivity);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const lastActivityAt = getDashboardWindowLastActivityAt();
      if (
        clientHasStaleAssets() &&
        dateDiffMs(lastActivityAt, new Date()) > MS_IN_3_HOURS
      ) {
        window.location.reload();
      }
    }, 10000);
    return () => clearInterval(timer);
  }, []);
};

export default useRefreshStaleInactiveTabs;
