import { setLatestVercelGitCommitShaOnWindow } from "@utils/assetsInfo";
import { useEffect } from "react";

const usePollLatestVercelGitCommitSha = (): void => {
  useEffect(() => {
    const timer = setInterval(setLatestVercelGitCommitShaOnWindow, 10000);
    return () => clearInterval(timer);
  }, []);
};

export default usePollLatestVercelGitCommitSha;
