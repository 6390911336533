export const fetchLatestVercelGitCommitSha = async (): Promise<
  string | undefined
> => {
  const res = await fetch("/api/vercel-git-commit-sha", {
    method: "GET",
  });
  const data = await res.json();
  return data.vercelGitCommitSha;
};

export const setLatestVercelGitCommitShaOnWindow = (): void => {
  fetchLatestVercelGitCommitSha().then((sha) => {
    // @ts-ignore
    window.latestVercelGitCommitSha = sha;
  });
};

export const getLatestVercelGitCommitShaFromWindow = (): string | undefined => {
  // @ts-ignore
  return window.latestVercelGitCommitSha;
};

export const clientHasStaleAssets = (): boolean => {
  const latestSha = getLatestVercelGitCommitShaFromWindow();
  const assetSha = getVercelGitCommitSha();
  return !!(assetSha && latestSha && latestSha !== assetSha);
};

export const setDashboardLoadedAtOnWindow = (): void => {
  // @ts-ignore
  window.dashboardLoadedAt = new Date().toISOString();
};
export const getDashboardLoadedAtFromWindow = (): string | undefined => {
  // @ts-ignore
  return window.dashboardLoadedAt;
};

export const getDashboardWindowLastActivityAt = (): Date => {
  // @ts-ignore
  if (!window.dashboardWindowLastActivityAt) {
    setDashboardWindowLastActivityAt();
  }
  // @ts-ignore
  return window.dashboardWindowLastActivityAt;
};

export const setDashboardWindowLastActivityAt = (): void => {
  // @ts-ignore
  window.dashboardWindowLastActivityAt = new Date();
};

export const getVercelGitCommitSha = (): string | undefined => {
  // @ts-ignore
  return process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
};
