import { setDashboardLoadedAtOnWindow } from "@utils/assetsInfo";
import { useEffect } from "react";

const useSetDashboardLoadedAtOnWindow = (): void => {
  useEffect(() => {
    setDashboardLoadedAtOnWindow();
  }, []);
};

export default useSetDashboardLoadedAtOnWindow;
