import { gql } from "@apollo/client";
import React, { FC, useMemo } from "react";
import { useFeatureFlagsQuery } from "./FeatureFlagContext.generated";

export type FlagDict = { [key: string]: boolean };

export const FeatureFlagContext = React.createContext<FlagDict>({});

type Props = {
  children: React.ReactNode;
};

const FeatureFlagContextProvider: FC<Props> = ({ children }) => {
  const { data } = useFeatureFlagsQuery();
  const flagDict = useMemo(() => {
    if (!data) {
      return {};
    }
    return data.FeatureFlags.reduce((accum: FlagDict, curr) => {
      accum[curr.name as string] = curr.enabled;
      return accum;
    }, {});
  }, [data]);
  return (
    <FeatureFlagContext.Provider value={flagDict}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

gql`
  query FeatureFlags {
    FeatureFlags {
      name
      enabled
    }
  }
`;
export default FeatureFlagContextProvider;
