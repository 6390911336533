import { ApolloLink } from "@apollo/client";
import { getOperationDefinitionOrDie } from "apollo-utilities";
import { isObjectLike, reduce, get, keyBy } from "lodash";
import {
  DocumentNode,
  NamedTypeNode,
  VariableDefinitionNode,
  IntrospectionInputObjectType,
  IntrospectionInputType,
  IntrospectionInputTypeRef,
  IntrospectionNamedTypeRef,
  TypeNode,
  IntrospectionType,
} from "graphql";
import introspection from "@src/types.generated.json";

export const cleanVariablesMiddleware = new ApolloLink((operation, forward) => {
  operation.variables = cleanVariables(operation.query, operation.variables);

  return forward(operation);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Variables = Record<string, any>;

const cleanVariables = (
  query: DocumentNode,
  variables?: Variables,
): Variables => {
  const { variableDefinitions = [] } = getOperationDefinitionOrDie(query);

  return reduceVariables(
    variableDefinitions,
    (variableDefinition) => variableDefinition.variable.name.value,
    (variableDefinition) =>
      getIntrospectionTypeFromTypeNode(variableDefinition.type),
    variables,
  );
};

const reduceVariables = <T>(
  items: readonly T[],
  getVarName: (item: T) => string,
  getIntrospectionType: (item: T) => [IntrospectionType, boolean],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: any,
): Variables => {
  if (!variables || !isObjectLike(variables)) return variables;

  return reduce(
    items,
    (vars: Variables, item: T) => {
      const varName = getVarName(item);
      const [type, isList] = getIntrospectionType(item);

      if (!(varName in variables)) return vars;

      return {
        ...vars,
        [varName]: extractInputVariables(type, variables[varName], isList),
      };
    },
    {},
  );
};

const extractInputVariables = (
  type: IntrospectionType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: any,
  isList: boolean,
): Variables => {
  if (isList) {
    if (Array.isArray(variables))
      return variables.map((v) => extractInputVariables(type, v, false));
    else return variables;
  }

  if (!isIntrospectionInputObjectType(type)) return variables;

  return reduceVariables(
    type.inputFields,
    (inputField) => inputField.name,
    (inputField) => getIntrospectionTypeFromInputTypeRef(inputField.type),
    variables,
  );
};

const isNamedTypeNode = (
  definition: TypeNode | VariableDefinitionNode,
): definition is NamedTypeNode => {
  return definition.kind === "NamedType";
};

const isListTypeNode = (
  definition: TypeNode | VariableDefinitionNode,
): definition is NamedTypeNode => {
  return definition.kind === "ListType";
};

const isIntrospectionNamedTypeRef = (
  inputTypeRef: IntrospectionInputTypeRef,
): inputTypeRef is IntrospectionNamedTypeRef<IntrospectionInputType> => {
  return Boolean(get(inputTypeRef, "name"));
};

const isIntrospectionListTypeRef = (
  inputTypeRef: IntrospectionInputTypeRef,
): inputTypeRef is IntrospectionNamedTypeRef<IntrospectionInputType> => {
  return inputTypeRef.kind === "LIST";
};

const isIntrospectionInputObjectType = (
  type: IntrospectionType,
): type is IntrospectionInputObjectType => {
  return type.kind === "INPUT_OBJECT";
};

const types: Record<string, IntrospectionType> = keyBy(
  introspection.__schema.types as IntrospectionType[],
  "name",
);

const getIntrospectionTypeFromTypeNode = (
  definition: TypeNode,
  isList = false,
): [IntrospectionType, boolean] => {
  if (isNamedTypeNode(definition))
    return [types[definition.name.value], isList];

  return getIntrospectionTypeFromTypeNode(
    definition.type,
    isList || isListTypeNode(definition),
  );
};

const getIntrospectionTypeFromInputTypeRef = (
  inputTypeRef: IntrospectionInputTypeRef,
  isList = false,
): [IntrospectionType, boolean] => {
  if (isIntrospectionNamedTypeRef(inputTypeRef))
    return [types[inputTypeRef.name], isList];

  return getIntrospectionTypeFromInputTypeRef(
    inputTypeRef.ofType,
    isList || isIntrospectionListTypeRef(inputTypeRef),
  );
};
