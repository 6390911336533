import { FieldFunctionOptions } from "@apollo/client";
import { uniqBy } from "lodash";

interface ResponseObject {
  __typename?: string;
}

interface PaginatedResponse<T> {
  totalCount: number;
  objects: T[];
}

export const mergePaginatedData = <T extends ResponseObject>(
  existing: Pick<PaginatedResponse<T>, "objects"> | undefined,
  incoming: PaginatedResponse<T>,
  options: FieldFunctionOptions,
): PaginatedResponse<T> => {
  const existingObjects = existing?.objects || [];
  const incomingObjects = incoming.objects || [];
  const paginationArgs = options?.args?.pagination || {
    offset: 0,
    limit: incomingObjects.length,
  };
  const { offset, limit } = paginationArgs;
  const mergedObjects = [...existingObjects];
  mergedObjects.splice(offset, limit, ...incomingObjects);
  return {
    totalCount: incoming.totalCount,
    objects: uniqBy(mergedObjects, "__ref"),
  };
};
