import { atom, useAtom } from "jotai";
import React, { createContext, useContext, useEffect } from "react";
import { CSSProperties } from "react";

type CursorType = CSSProperties["cursor"];

interface BodyCursorTypeContextState {
  setCursorType: (cursor: CursorType) => void;
  cursorType: CursorType;
}

const BodyCursorTypeContext = createContext<
  BodyCursorTypeContextState | undefined
>(undefined);

export const useBodyCursorType = (): BodyCursorTypeContextState => {
  const context = useContext(BodyCursorTypeContext);
  if (!context) {
    throw new Error(
      "useCursorType must be used within a CursorTypeContextProvider",
    );
  }
  return context;
};
export const cursorTypeAtom = atom<CursorType>("auto");

export const BodyCursorTypeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }): JSX.Element => {
  const [cursorType, setCursorType] = useAtom(cursorTypeAtom);
  useEffect(() => {
    document.body.style.cursor = cursorType || "auto";
  }, [cursorType]);
  const value: BodyCursorTypeContextState = {
    setCursorType,
    cursorType,
  };
  return (
    <BodyCursorTypeContext.Provider value={value}>
      {children}
    </BodyCursorTypeContext.Provider>
  );
};
